import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "my-4 px-10 py-1" }
const _hoisted_2 = { class: "flex space-x-4 mb-3" }
const _hoisted_3 = { class: "font-bold" }
const _hoisted_4 = { class: "font-bold" }
const _hoisted_5 = { class: "mb-3 flex justify-end items-center space-x-3" }
const _hoisted_6 = { class: "box py-5 relative mb-3" }
const _hoisted_7 = { class: "mb-3 flex justify-between justify-items-center" }
const _hoisted_8 = { class: "flex w-full justify-between space-x-5" }
const _hoisted_9 = { class: "items-center" }
const _hoisted_10 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _directive_loading = _resolveDirective("loading")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ol", _hoisted_2, [
      _createElementVNode("li", _hoisted_3, [
        _createVNode(_component_router_link, { to: "" }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode("Home")
          ])),
          _: 1
        })
      ]),
      _cache[3] || (_cache[3] = _createElementVNode("li", null, ">", -1)),
      _createElementVNode("li", _hoisted_4, [
        _createVNode(_component_router_link, { to: "/renewable-options" }, {
          default: _withCtx(() => _cache[2] || (_cache[2] = [
            _createTextVNode("Renewable Options")
          ])),
          _: 1
        })
      ]),
      _cache[4] || (_cache[4] = _createElementVNode("li", null, ">", -1)),
      _cache[5] || (_cache[5] = _createElementVNode("li", { class: "font-bold" }, "Transaction Details", -1))
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("button", {
        type: "button",
        class: "btn btn--green-primary",
        onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => ($setup.router.push('/renewable-options/buy-recs')), ["prevent"]))
      }, " Buy RECs ")
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _cache[7] || (_cache[7] = _createElementVNode("h1", { class: "text-left text-lg font-semibold" }, " Renewable Energy Attributes ", -1)),
          _createElementVNode("p", null, " Vintage: " + _toDisplayString($setup.renewableOptionDetails.Vintage
                ? `${$setup.renewableOptionDetails.Vintage} - ${$setup.renewableOptionDetails.Vintage}`
                : ""), 1),
          _createElementVNode("p", null, " Reporting time: " + _toDisplayString(`${
                $setup.renewableOptionDetails.FromDate
                  ? $setup.formatToUTCLocalMonth($setup.renewableOptionDetails.FromDate)
                  : "N/A"
              } - ${
                $setup.renewableOptionDetails.ToDate
                  ? $setup.formatToUTCLocalMonth($setup.renewableOptionDetails.ToDate)
                  : "N/A"
              }`), 1),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("button", {
              type: "button",
              class: "btn bg-none border-gray-700 items-center",
              onClick: $setup.exportExcel
            }, [
              _createVNode($setup["BaseSvgIcon"], {
                class: "inline-block text-cafe-dark w-4 h-4 mr-3",
                name: "export_excel"
              }),
              _cache[6] || (_cache[6] = _createElementVNode("label", { class: "text-gray-secondary cursor-pointer" }, "Export Retire RECs", -1))
            ])
          ])
        ])
      ]),
      (
          $setup.renewableOptionDetails && $setup.renewableOptionDetails.Details?.length > 0
        )
        ? _withDirectives((_openBlock(), _createBlock(_component_el_table, {
            key: 0,
            class: "w-full table-custom",
            style: { borderRadius: '4px' },
            data: $setup.renewableOptionDetails?.Details,
            height: "66vh",
            stripe: "",
            "cell-class-name": "text-cafe-dark",
            "header-row-class-name": "tableHeaderRow",
            "row-class-name": "tableRow",
            "element-loading-text": "Loading...",
            "element-loading-background": "rgba(0, 0, 0, 0.6)"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_table_column, {
                prop: "SerialNumber",
                label: "Serial Number"
              }),
              _createVNode(_component_el_table_column, {
                prop: "Registry",
                label: "Registry"
              }),
              _createVNode(_component_el_table_column, {
                prop: "Vintage",
                sortable: "",
                label: "Vintage"
              }),
              _createVNode(_component_el_table_column, {
                prop: "Amount",
                sortable: "",
                label: "Amount"
              }),
              _createVNode(_component_el_table_column, {
                prop: "EnergyValue",
                sortable: "",
                label: "Type"
              }),
              _createVNode(_component_el_table_column, {
                prop: "Source",
                sortable: "",
                label: "Source"
              })
            ]),
            _: 1
          }, 8, ["data"])), [
            [_directive_loading, $setup.isRenewableOptionDetails]
          ])
        : _createCommentVNode("", true)
    ]),
    (
        $setup.renewableOptionDetails && $setup.renewableOptionDetails.Details?.length > 0
      )
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
          _createVNode($setup["GoogleMap"], {
            data: $setup.renewableOptionDetails.Details,
            dataCenterMap: $setup.center,
            classMap: _ctx.styles['map-size'],
            contentMarker: $setup.TypeContentMarker.CONTENT_DETAIL_REC_ID,
            mapTypeId: $setup.TypeMap.HYBRID,
            markerWhite: true
          }, null, 8, ["data", "dataCenterMap", "classMap", "contentMarker", "mapTypeId"])
        ]))
      : _createCommentVNode("", true)
  ])), [
    [_directive_loading, $setup.isLoadingDownLoad]
  ])
}